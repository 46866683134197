import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import RichText from '~components/RichText';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    maxWidth: '900px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '300px'
    }
  },
  header: {
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
      color: '#01153D',
      fontSize: 64,
      fontFamily: 'Inter',
      fontWeight: '600',
      lineHeight: '118%',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: 32
      }
    },
    '& > * > strong': {
      color: '#9B9B9B',
      fontWeight: '600',
      [theme.breakpoints.down('xs')]: {
        color: '#01153D'
      }
    }
  }
}));

const IntroductionTitle = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      <RichText html={title?.html} externalClassName={classes.header} verticalSpacing={0} />
    </div>
  );
};

IntroductionTitle.propTypes = {
  title: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }).isRequired
};

export default IntroductionTitle;
