import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Container from '~components/Container';
import AnimatedPlayButton from '~components/slices/about-hero/components/AnimatedPlayButton';
import VideoDialog from '~components/VideoPopupCard/VideoDialog';
import { useLocation } from '@reach/router';
import IntroductionTitle from './IntroductionTitle';
import AboutAuthor from './AboutAuthor';
import PlaylistSampleImg from './images/introduce_bg_playlists_image.png';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '106px',
    paddingBottom: '72px',
    backgroundColor: '#FBFBFB',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '40px',
      paddingBottom: '48px'
    }
  },
  playerArea: {
    width: '100%',
    position: 'relative',
    marginTop: '90px',
    marginBottom: '115px',
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      marginTop: '60px',
      marginBottom: '88px'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '30px',
      marginBottom: '44px'
    }
  },
  playerBackground: {
    position: 'absolute',
    overflowX: 'hidden',
    justifySelf: 'center',
    alignSelf: 'center',
    zIndex: 5,
    [theme.breakpoints.down('xs')]: {
      height: '200px'
    }
  },
  playerImage: {
    justifySelf: 'center',
    alignSelf: 'center',
    zIndex: 10,
    maxHeight: '600px',
    [theme.breakpoints.down(850)]: {
      width: '90%'
    }
  },
  authorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 140,
    [theme.breakpoints.down('xs')]: {
      gap: 70
    }
  }
}));

const Introduction = ({ title, videoCover, videoUrl, authors }) => {
  const classes = useStyles();
  const location = useLocation();
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (location?.state?.playIntroVideo) {
      const intro = document.getElementById('ab-landing-intro');
      if (intro) {
        intro.scrollIntoView({ behavior: 'smooth' });
        setIsPlaying(true);
        // Below line is important. Otherwise, if you navigate to some other page and comes back, video starts autoplaying.
        // What we do here is, when video starts to play, we set the playIntroVideo to false. We are using window as it doesn't trigger a re-render
        window.history.replaceState({ ...window.history.state, playIntroVideo: false }, '');
      }
    }
  }, [location]);

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
  };
  const handleClosePopup = () => {
    setIsPlaying(false);
  };

  return (
    <>
      {isPlaying && <VideoDialog url={videoUrl} onClose={handleClosePopup} show={isPlaying} />}
      <div className={classes.container} id="ab-landing-intro">
        <IntroductionTitle title={title} />
        <div className={classes.playerArea}>
          <img src={videoCover?.url} alt="Aleph Beta Intro Video" className={classes.playerImage} />
          <img
            src={PlaylistSampleImg}
            alt="Aleph Beta playlist sample"
            className={classes.playerBackground}
          />
          <AnimatedPlayButton onClick={handlePlayButtonClick} zIndex={12} />
        </div>

        <Container center>
          <div>
            {authors?.length && (
              <div className={classes.authorContainer}>
                {authors.map(author => (
                  <AboutAuthor key={author?.title?.text} {...author} />
                ))}
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

Introduction.propTypes = {
  title: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  videoCover: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape({
        html: PropTypes.string,
        text: PropTypes.string
      }),
      description: PropTypes.shape({
        html: PropTypes.string,
        text: PropTypes.string
      }),
      avatar: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
      })
    })
  )
};

Introduction.defaultProps = {
  authors: []
};

export default Introduction;
