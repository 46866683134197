import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import RichText from '~components/RichText';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24
  },
  media: {
    width: 132,
    height: 132,
    borderRadius: 999,
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: 80,
      width: 80
    }
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '810px',
    '& > *': {
      color: '#01153D',
      fontSize: 52,
      fontFamily: 'Inter',
      textAlign: 'center',
      fontWeight: '600',
      lineHeight: '118%',
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.down('xs')]: {
        fontSize: 32
      }
    },
    '& > * > strong': {
      color: '#86868B',
      fontWeight: '600'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '298px',
      marginTop: '-8px'
    }
  },
  description: {
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '790px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    },
    '& > *': {
      color: '#000000',
      fontSize: '20px',
      lineHeight: '28px',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontFamily: 'Inter',
        fontSize: '14px',
        lineHeight: '20px'
      }
    },
    '& > p:empty': {
      height: '24px',
      [theme.breakpoints.down('xs')]: {
        height: '20px'
      }
    },
    '& > h1': {
      fontSize: '42px',
      lineHeight: '48px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '35px',
        lineHeight: '41px'
      }
    },
    '& > h2': {
      fontSize: '35px',
      lineHeight: '41px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '28px',
        lineHeight: '32px'
      }
    },
    '& > h3': {
      fontSize: '28px',
      lineHeight: '32px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
        lineHeight: '28px'
      }
    },
    '& > * > strong': {
      fontWeight: '500 !important'
    },
    '& > * > a': {
      fontSize: '16px',
      color: '#000000',
      fontWeight: 500,
      textDecoration: 'underline',
      '&:hover': {
        color: '#317DC9'
      }
    }
  }
}));

const AboutAuthor = ({ title, description, avatar }) => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <img src={avatar?.url} alt={avatar?.alt} className={classes.media} />
      <RichText html={title?.html} externalClassName={classes.title} />
      <RichText html={description?.html} externalClassName={classes.description} />
    </section>
  );
};

AboutAuthor.propTypes = {
  title: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  description: PropTypes.shape({
    html: PropTypes.string
  }),
  avatar: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  })
};

AboutAuthor.defaultProps = {
  description: null,
  avatar: null
};

export default AboutAuthor;
